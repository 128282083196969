<template>
    <basic-page class="press-kit" :pagedata="this.pageData">
        <div class=" container-beige intro container">
            <h3>Maggio 2, 2021</h3>
            <div class="title">
                <div>
                    <h2>
                        Il nostro Press Kit: lorem ipsum
                    </h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quis enim natus quibusdam dolore,
                        quisquam, eligendi praesentium soluta itaque pariatur, maxime iusto sunt quaerat quo ad
                        excepturi? Ratione corporis delectus similique.
                    </p>
                </div>
                <div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum pariatur corporis rerum
                        voluptate beatae nisi dolorum distinctio? Voluptates minima ratione dolor. In minima
                        consequuntur eum quae nisi fugit veniam assumenda.
                    </p>
                    <button class="button">
                        {{ this.lang=='it' ? 'Per scaricare il Presskit, clicca qui' : 'To download the Presskit, click here'}}
                    </button>
                </div>
            </div>
        </div>
    </basic-page>
</template>

<script>
    import BasicPage from './BasicPage.vue'
    import {
        fetchSinglePage, getTranslation
    } from '../libs/drupalClient'
    export default {
        components: {
            BasicPage
        },
        name: 'press-kit',
        data: () => {
            return {
                currentPageId: "3cac23a7-e2d0-449f-95a6-bfbad931d0b6",
                pageData: {
                    attributes: {}
                }
            }
        },
        mounted() {
            fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            })
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            }
        },
        watch: {
            lang() {
                getTranslation(this.$route.name, this.lang)
                .then(res => {
                    this.$router.push({path: res})
                })

                fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                }) 
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors.scss";

    .press-kit {
        .container-beige.intro {
            @media (min-width:1200px) {
                padding: 6rem 5rem;
            }

            background: #D2BC75 url("../assets/filigrana-press-kit.png") bottom left;
            background-repeat: no-repeat;
        }

        .container-beige.intro h3::after {
            background-color: #474E32;
        }

        .container-beige.intro h3 {
            color: #474E32;
        }

        h2 {
            margin-top: 2rem;
            text-align: left;
        }
        p{
            margin: 1rem;
        }
    }

    .press-kit .container {
        background-color: $mainColor;
        padding: 4rem 8rem;
        margin-bottom: 2rem;

        h3 {
            color: #000;
            font-family: 'Poppins';
            font-size: 1em;
            margin: 2rem 0rem 5rem 0rem;
            margin-top: 0;
            position: relative;
            text-align: center;

            &::after {
                background: #000;
                position: absolute;
                width: 150px;
                height: 1px;
                bottom: -20px;
                transform: translateX(-50%);
                left: 50%;
                z-index: 1;
                content: "";
            }
        }

        .title {
            display: flex;
            justify-content: space-between;

            h2 {
                line-height: 2.4rem;
                width: 80%;
                font-size: 2.5rem;
                font-family: "Cinzel";
                color: #fff;
            }

            div {
                width: 45%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }

        .description {
            padding: 2rem 0rem 2rem 0rem;

            h2 {
                line-height: 2.4rem;
                border-bottom: 1px solid #70707023;
                padding: 2rem 0rem 1rem 0rem;
                margin-bottom: 2rem;
                font-size: 1.125rem;
                text-transform: uppercase;
                font-family: "Poppins";
            }
        }
    }


    @media (max-width:992px) {
        .press-kit .container {
            padding: 4rem 4rem;

            .title {
                flex-direction: column;

                &>div {
                    width: auto;

                    p {
                        margin: 1rem 0rem;
                    }
                }
            }
        }
    }

    @media (max-width:768px) {
        .press-kit .container {
            padding: 1rem 2rem 4rem 2rem;

            .title h2 {
                width: auto;
            }

            h3 {
                margin: 2rem 0rem 3rem 0rem;
            }
        }
    }
</style>